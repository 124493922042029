.gemEcosystem-text {
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.header-shadow {
  background: #fff;
  box-shadow: none;
  border-bottom: 1px solid #d1dae7;
}
@media (min-width: 1024px) {
  .header-shadow {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    border-bottom: none;
  }
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
}
.header-link-wrapper {
  position: relative;
}

/* This is the underline element of header links */
.header-link::after {
  content: "";
  height: 0.25rem;
  width: 0;
  border-radius: 0.125rem;
  background: #407ec4;
  position: absolute;
  left: 0;
  bottom: 0.75rem;
  transition: 0.3s ease-out;
  pointer-events: none;
}
.header-link-wrapper:hover > .header-link::after {
  width: 100%;
}
.header-link-active::after {
  content: "";
  height: 0.25rem;
  width: 100%;
  border-radius: 0.125rem;
  background: #407ec4;
  position: absolute;
  left: 0;
  bottom: 0.75rem;
  transition: 0.3s ease-out;
  pointer-events: none;
}

/* This is the menu onHover of header-link */
.header-link-menu-wrapper {
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 30 !important;
  padding: 3rem;
  margin: 0rem 5rem 0rem 0rem;
  border-radius: 0rem 0rem 1.25rem 1.25rem;
  background: #fff;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08);
  opacity: 0;
  transition: 0.3s ease-out;
  width: max-content;
  pointer-events: none;
}
.header-link-wrapper:hover > .header-link-menu-wrapper,
.header-link-menu-wrapper:hover {
  opacity: 1;
  pointer-events: all;
}

/* Menu elements  */
.header-link-menu-heading {
  color: #010101;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.05rem;
}
.header-link-menu-description {
  color: #5b5b5b;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
/* This is the underline element of menu description */
.header-link-menu-description::after {
  content: "";
  height: 0.13rem;
  width: 10%;
  border-radius: 0.125rem;
  background: #407ec4;
  position: absolute;
  bottom: -0.25rem;
  left: 0;
  pointer-events: none;
}
.header-link-menu-content-heading {
  color: #010101;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.035rem;
}
.header-link-menu-content-link {
  color: #5b5b5b;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.button-wrapper {
  position: relative;
}

.button-options {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  visibility: hidden; 
  background-color: #fff;
  height: 8rem;
  width: 20rem;
  display: flex;
  flex-direction: column; 
  align-items: center; 
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
  /* gap:1.5rem !important; */
  
}

.button-wrapper:hover .button-options {
  visibility: visible; 
}

.custom-btn{
  background-color: #ffffff;
  color: black;
  border-radius: 2rem !important;
  /* border:1px solid black !important; */
  flex:1;
}